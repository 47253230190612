import { Application } from "@hotwired/stimulus"
import "htmx.org"

// ui
import Theme from "./components/Theme"

// users
import Input from "../../../users/static_src/users/components/Input.js"

const app = Application.start()
app.register("theme", Theme)
app.register("input", Input)
